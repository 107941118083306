import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
// import { Link } from "./../util/router";
import { wave } from "../assets";
import Icon from "./Icon";

function CtaSection(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-10">
        <div className="text-center">
          <div className="relative inline-flex items-center justify-center w-20 h-20 mx-auto mb-10 text-emerald-500">
            <div className="absolute inset-0 transform scale-105 bg-sky-800 rounded-xl rotate-6" />
            <div className="absolute inset-0 transform scale-105 bg-sky-100 rounded-xl -rotate-6" />
            <div className="relative">
              <Icon
                src={wave}
                alt=""
                width={65}
                height={50}
                className="inline-block w-16 h-20 mb-5 text-blue-600"
              />
              {/* <FireIcon className="inline-block w-10 h-10" /> */}
            </div>
          </div>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
          {/* //dynamic render of button if props.button is true */}
          <div className="text-center">
            {props.buttonLink && (
              <a href="https://getwavery.gumroad.com/l/whisperscript">
                <Button
                  // component={Link}
                  // to="/pricing"
                  size="xl"
                  endIcon={
                    <ArrowRightIcon className="inline-block w-5 h-5 opacity-70" />
                  }
                >
                  Let's get started
                </Button>
              </a>
            )}
            {props.customButton && (
              <div className="text-center">
                <a href={`${window.location.origin}/launchapp`}>
                  <Button
                    size="lg"
                    // href={"props.customButtonUri"}
                    variant="simple"
                    className="bg-white"
                    // startIcon={<img src={discordIcon} alt="Discord Icon" />}
                  >
                    Launch WhisperScript on Desktop
                  </Button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default CtaSection;
