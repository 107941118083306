import {
  waveryIcon,
  searchWorkflow,
  dragDropWorkflow,
  mainWorkflow,
  whisperScriptMainIcon,
} from "../assets";

export const products = [
  {
    title: "WhisperScript",
    description: "Simple Transcription Tool, Supercharged by Whisper",
    price: 10,
    image: whisperScriptMainIcon,
    url: "/#whisperscript",
    iconBg: "blue",
    tag: "Beta",
  },
  {
    title: "WhisperScript 2",
    description: "The Ultimate AI Transcription Software for your Mac",
    price: 10,
    image: waveryIcon,
    url: "/products/whisperscript",
    iconBg: "blue",
    tag: "Coming Soon!",
  },
  // {
  //   title: "Wavery",
  //   description: "Powerful Audio AI Workflow Toolchain for Media Professionals",
  //   price: 20,
  //   image: waveryIcon,
  //   url: "/purchase/wavery",
  //   iconBg: "purple",
  //   tag: "coming soon!",
  // },
];

export const features = [
  {
    title: "Search Workflow",
    description:
      "Quickly search through your audio to find just what you are looking for.",
    image: searchWorkflow,
  },
  {
    title: "Drag & Drop Workflow",
    description:
      "Easily drag and drop your audio files into the app to start transcribing.",
    image: dragDropWorkflow,
  },
  {
    title: "Main Workflow",
    description:
      "Efficiently manage your audio transcriptions with WhisperScript’s powerful features.",
    image: mainWorkflow,
  },
];

export const plans = [
  {
    id: "whisperscriptMonthly",
    name: "Monthly",
    price: "9",
    billingCycle: "/ month",
    subtitle: "For Short-term Projects",
    mode: "subscription",
    perks: [
      <>
        <strong>Unlimited</strong> Transcription
      </>,
      <>
        <strong>On-Device</strong> Processing
      </>,
      <>
        <strong>Easy</strong> Editing Tools
      </>,
      <>
        <strong>Instant</strong> Audio Playback
      </>,
      <>
        <strong>Batch</strong> Processing
      </>,
      <>
        <strong>99 Languages</strong> Supported
      </>,
      <>
        <strong>Multi-Format</strong> Exports
      </>,
      <>
        <strong>3 Day</strong> Trial
      </>,
    ],
  },
  {
    id: "whisperscriptLifetime",
    name: "Lifetime",
    price: "249",
    billingCycle: "one-time",
    subtitle: "Best Value",
    featured: true,
    mode: "payment",
    perks: [
      <>
        <strong>All Perks</strong> of Monthly Plan +
      </>,
      <>
        <strong>Lifetime</strong> Access
      </>,
      <>
        <strong>One-Time</strong> Payment
      </>,
      <>
        <strong>Super Fast</strong> Support
      </>,
      <>
        <strong>Lifetime Discord</strong> Tiers
      </>,
    ],
  },
  {
    id: "whisperscriptAnnual",
    name: "Annual",
    price: "99",
    billingCycle: "/ year",
    subtitle: "Save 15% Annually",
    mode: "subscription",
    perks: [
      <>
        <strong>Save</strong> 15% Annually!
      </>,
      <>
        <strong>All Perks</strong> of Monthly Plan +
      </>,
      <>
        <strong>Member's Discord</strong> Access
      </>,
      <>
        <strong>Priority</strong> Support
      </>,
      <>
        <strong>7 Day</strong> Trial
      </>,
    ],
  },
];
