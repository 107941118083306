import React from "react";
import "./../styles/global.css";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import ProductPage from "./product";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Router, Routes, Route } from "./../util/router";
//import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import { Analytics } from "../util/analytics";
import { Chat } from "../util/Chat";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import OrderConfirmationPage from "./orderConfirmation";
import LaunchElectronPage from "./launchElectron";
import Notification from "../components/Notification";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <Analytics />
          <Chat />

          <>
            <Notification />
            <Navbar bgColor="bg-primary" />
            <Routes>
              <Route path="/" element={<IndexPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/auth/:type" element={<AuthPage />} />
              <Route path="/settings/:section" element={<SettingsPage />} />
              <Route path="/legal/:section" element={<LegalPage />} />
              <Route path="/purchase/:plan" element={<PurchasePage />} />
              <Route path="/launchapp" element={<LaunchElectronPage />} />
              <Route path="/products/whisperscript" element={<ProductPage />} />
              <Route
                path="/order/success"
                element={<OrderConfirmationPage />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <Footer
              size="md"
              bgColor="bg-primary"
              bgImage=""
              bgImageOpacity={1}
              textColor="text-gray-100"
              sticky={true}
            />
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
