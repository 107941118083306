import React, { useState } from "react";
import Section from "./Section";
import ReauthModal from "./ReauthModal";
import { Link } from "./../util/router";
import FormAlert from "./FormAlert";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPassword from "./SettingsPassword";
import SettingsBilling from "./SettingsBilling";
import { useAuth } from "./../util/auth";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { discordIcon } from "../assets";
import { waveryIcon } from "../assets";
import SettingsLicense from "./SettingsLicense";
// import discordIcon from "../assets/discord-mark-blue.svg";

function SettingsSection(props) {
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    general: true,
    password: true,
    license: true,
    billing: false,
  };

  const section = validSections[props.section] ? props.section : "general";

  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };

  const userName = auth.user.name || auth.user.email;

  function hasProductAccess(products, appName) {
    return products?.some(
      (product) => product.appName === appName && product.hasAccess === true
    );
  }

  const hasWhisperScriptAccess = hasProductAccess(
    auth.user?.products,
    "WhisperScript"
  );

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={auth.user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )}
      <SectionHeader
        title={"Welcome " + userName + "!"}
        subtitle="Manage your account settings"
        strapline={props.strapline}
        className="text-center"
      />
      <nav className="flex items-center max-w-md mx-auto mb-12 border-b border-gray-200">
        {[
          validSections.general && { section: "general", label: "General" },
          validSections.password && { section: "password", label: "Password" },
          validSections.license && { section: "license", label: "License" },
          // validSections.billing && { section: "billing", label: "Billing" },
        ].map((tab) => (
          <Link
            to={`/settings/${tab.section}`}
            className={
              "flex items-center grow justify-center px-3 md:px-4 -mb-px py-4 border-b-2" +
              (tab.section === section
                ? " text-blue-500 border-blue-500"
                : "") +
              (tab.section !== section
                ? " border-transparent hover:text-blue-500"
                : "")
            }
            key={tab.section}
          >
            {tab.label}
          </Link>
        ))}
      </nav>
      <div className="container max-w-md mx-auto mt-5">
        {formAlert && (
          <div className="mb-4">
            <FormAlert type={formAlert.type} message={formAlert.message} />
          </div>
        )}

        {section === "general" && <SettingsGeneral onStatus={handleStatus} />}

        {section === "password" && <SettingsPassword onStatus={handleStatus} />}
        {section === "license" && <SettingsLicense onStatus={handleStatus} />}

        {section === "billing" && <SettingsBilling onStatus={handleStatus} />}
      </div>

      <div className="container max-w-md py-20 mx-auto mt-5">
        <div className="flex flex-col justify-center gap-3">
          {hasWhisperScriptAccess && (
            <>
              <Button
                size="lg"
                href="https://github.com/getwavery/whisperscript-2-releases/releases/download/v2.0.0-alpha.1/WhisperScript-2.0.0-alpha.1-arm64-mac.dmg"
                variant="simple"
                startIcon={<img src={waveryIcon} alt="Discord Icon" />}
              >
                Download WhisperScript 2 (Apple Silicon)
              </Button>
              <Button
                size="lg"
                href={`${window.location.origin}/launchapp`}
                variant="simple"
                startIcon={<img src={discordIcon} alt="Discord Icon" />}
              >
                Launch WhisperScript on Desktop
              </Button>
            </>
          )}
          <Button
            size="lg"
            href="https://discord.gg/D45a4yQRp8"
            variant="simple"
            startIcon={<img src={discordIcon} alt="Discord Icon" />}
            style={{ backgroundColor: "#5865F2", color: "#fff" }}
          >
            Join our Discord Server
          </Button>
        </div>
      </div>
    </Section>
  );
}

export default SettingsSection;
