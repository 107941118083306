import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "../components/HeroSection2";
import HeroSection3 from "./../components/HeroSection3";
import FeaturesSection from "./../components/FeaturesSection";
import FeaturesSection2 from "./../components/FeaturesSection2";
import StatsSection from "./../components/StatsSection";
// import StatsSection2 from "./../components/StatsSection2";
// import StatsSection3 from "./../components/StatsSection3";
// import ContentSection from "./../components/ContentSection";
// import TestimonialsSection from "./../components/TestimonialsSection";
// import ClientsSection from "./../components/ClientsSection";
// import ClientsSection2 from "./../components/ClientsSection2";
// import CtaSection2 from "./../components/CtaSection2";
// import CtaSection3 from "./../components/CtaSection3";
import CtaSection from "./../components/CtaSection";
import { bg01, bg02 } from "../assets";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection2
        title="Unlimited Privacy-First"
        titleLower="AI Transcription."
        subtitle="Unparalleled accuracy and privacy, powered by OpenAI's Whisper"
        strapline="WhisperScript"
        size="lg"
        bgColor="bg-primary"
        bgImage={bg01}
        bgImageOpacity={0.5}
        textColor="text-sky-100"
      />
      <HeroSection3
        title="Your All-in-One Transcription Solution"
        subtitle="Edit, export, and manage your audio transcriptions with WhisperScript's powerful, privacy-focused features."
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        anchor="whisperscript"
      />
      <FeaturesSection
        title="The Ultimate Feature-Rich Transcription Tool"
        subtitle="With WhisperScript, listening through hours of interviews to find that one section of audio is a thing of the past. Quickly search through your audio to find just what you are looking for."
        strapline="WhisperScript:"
        size="md"
        bgColor="bg-primary"
        bgImage={bg02}
        bgImageOpacity={0.3}
        textColor="text-gray-200"
        anchor="features"
      />
      <FeaturesSection2
        title="Tailored for Your Needs"
        subtitle="WhisperScript supports multiple audio and video formats, batch processing, and offers customizable model parameters to fit your specific requirements."
        strapline="Transcription Workflows"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      <StatsSection
        title="Proven Performance"
        subtitle="Trusted by professionals for efficient and secure transcriptions."
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      {/* <StatsSection2
        title="Trusted by thousands of professionals"
        subtitle="Web developers from all over the world are using our products. Join them and build something amazing!"
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      /> */}
      {/* <StatsSection3
        title="Trusted by thousands of professionals"
        subtitle="Web developers from all over the world are using our products. Join them and build something amazing!"
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      /> */}
      {/* <ContentSection
        title="Latest Posts"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      /> */}
      {/* <TestimonialsSection
        title="Customer Testimonials"
        subtitle=""
        strapline="Real Feedback"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      /> */}
      {/* <ClientsSection
        title="Don't just listen to us:"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      /> */}
      {/* <ClientsSection2
        title=""
        subtitle=""
        strapline="Trusted by these amazing companies"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      /> */}
      <CtaSection
        title={
          <>
            Ready to Enhance Your{" "}
            <span className="text-gradient">Transcription</span> Workflow?
          </>
        }
        subtitle="Try WhisperScript for free and experience the difference."
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        buttonLink="https://getwavery.gumroad.com/l/whisperscript"
        buttonText="Get Started Today!"
      />
      {/* <CtaSection
        title="Create an account today!"
        subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        buttonLink="https://getwavery.gumroad.com/l/whisperscript"
        buttonText="Get Started Today!"
      /> */}
      {/* <CtaSection2
        title="Create an account today!"
        subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      /> */}
      {/* <CtaSection3
        title="Ready? Let’s do it!"
        subtitle="Get your own custom dashboard and start building amazing services, always with the most solid and rock steady foundation."
        strapline=""
        size="lg"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      /> */}
    </>
  );
}

export default IndexPage;
