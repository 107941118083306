import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "../util/auth";

function SettingsLicense(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .insertProductFromGumroadKey(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message:
            "Your Lifetime WhisperScript Pro has been activated! 🎉 Please refresh the page.",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  const whisperScriptProduct = auth.user?.products?.find(
    (product) =>
      product.appName === "WhisperScript" && product.hasAccess === true
  );
  const gumroadlicenseCode = whisperScriptProduct?.gumroadLicenseKey;
  const licenseCode = whisperScriptProduct?.id;

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      {!gumroadlicenseCode ? (
        <>
          <TextField
            type="text"
            id="licenseKey"
            placeholder="WhisperScript Pro Gumroad License Key"
            label="WhisperScript Pro License Key"
            textColor="text-gray-900"
            error={errors.licenseKey}
            {...register("licenseKey")}
          />
          <p className="py-4 text-gray-600 text-md">
            If you have a WhisperScript Pro Gumroad license key, you can enter
            it here.
          </p>
          <Button
            type="submit"
            size="md"
            variant="primary"
            disabled={pending}
            isBlock={true}
          >
            {!pending && <>Register Key</>}
            {pending && <LoadingIcon className="w-6" />}
          </Button>
        </>
      ) : (
        <div className="space-y-4">
          <p className="text-lg text-gray-300">
            Your WhisperScript Pro license key is <strong>{licenseCode}</strong>
            .
          </p>
          <p className="text-sm text-gray-600">
            Please keep this key safe and secure.
          </p>
        </div>
      )}
    </form>
  );
}

export default SettingsLicense;
