import React from "react";
import Meta from "./../components/Meta";
import HeroSection3 from "../components/HeroSection3";
import AuthSection from "../components/AuthSection";
import { useRouter } from "./../util/router";

function OrderConfirmationPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="OrderConfirmed" />
      <HeroSection3
        title="Your Order Has Been Confirmed"
        subtitle="Get started right away with your new transcription toolbox"
        strapline="THANK YOU!!"
        size="lg"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      <AuthSection
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        type={router.query.type}
        providers={["google", "facebook", "twitter"]}
        afterAuthPath={router.query.next || "/dashboard"}
      />
    </>
  );
}

export default OrderConfirmationPage;
