import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import { Link } from "../util/router";
import SectionHeaderHero from "./SectionHeaderHero";
import Button from "./Button";
import { waveryIconLarge, mainWorkflow } from "../assets";
import styles from "../styles/style";

function HeroSection2(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="absolute inset-x-0 bottom-0 text-blue-700 text-opacity-50">
        {/* SVG FOR WAVE BACKGROUND GRAPHIC */}
        {/* <svg fill="currentColor" viewBox="0 0 500 150">
          <path d="M-0.84,34.03 C187.63,148.52 327.03,-4.44 499.72,40.95 L500.00,150.00 L0.00,150.00 Z" />
        </svg> */}
      </div>
      <div className="container relative">
        <div className="text-center">
          <Link
            to="/"
            className="inline-block mb-10 text-blue-600 hover:text-blue-400"
          >
            {/* <CubeTransparentIcon className="inline-block w-12 h-12" /> */}
            {/* WhisperScript Icon */}
            <div className="sm:w-[150px] w-[125px] sm:h-[150px] h-[125px] sm:mb-5 mb-5">
              <img
                src={waveryIconLarge}
                alt="WaveryIcon"
                className="aspect-square app-icon w-full h-auto rounded-[34px]"
              />
            </div>
          </Link>
          <SectionHeaderHero
            title={props.title}
            titleLower={props.titleLower}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
        </div>

        <div className="flex flex-col items-center justify-center pt-10 pb-16 space-y-2 sm:flex-row sm:items-center sm:justify-center sm:space-y-0 sm:space-x-2">
          <a href="https://getwavery.gumroad.com/l/whisperscript">
            <Button
              // component={Link}
              // to="/pricing"
              size="xl"
              variant="primary"
              endIcon={
                <ArrowRightIcon className="inline-block w-5 h-5 opacity-70" />
              }
            >
              Download for Mac Now
            </Button>
          </a>
          <a href={`${window.location.origin}/#features`}>
            <Button
              /* component={Link} to="/#features" */ size="xl"
              variant="light"
            >
              Learn more
            </Button>
          </a>
        </div>
        <div className="pb-4 md:pb-0">
          <div className="relative mx-5 lg:mx-32">
            {/* ROTATED BG SQUARES */}
            {/* <div className="absolute inset-0 -m-4 transform bg-waveryBlue rounded-xl bg-opacity-20 rotate-2" /> */}
            {/* <div className="absolute inset-0 -m-4 transform bg-opacity-25 bg-waveryBlue rounded-xl -rotate-2" /> */}
            {/* <img
              src={whisperVideoMainFallback}
              alt="whisperscript screenshot"
              className={`aspect-[451/300] absolute top-0`}
            /> */}
            <div
              style={{ aspectRatio: "auto 451/300" }}
              className={`${styles.flexCenter} my-2 sm:max-h-[600px] max-h-[380px] w-full`}
            >
              <div className="rcorners aspect-[451/300] h-full sm:max-h-[600px] max-h-[380px]">
                <div className="relative">
                  {/* <img
                    src={whisperVideoMainFallback}
                    alt="whisperscript screenshot"
                    className={`aspect-[451/300] absolute top-0`}
                  /> */}
                  <video
                    src={mainWorkflow}
                    type="video/mp4"
                    alt="whisperscript video"
                    className={`absolute z-[1]`}
                    autoPlay
                    loop
                    playsInline
                    muted
                  ></video>
                </div>
              </div>
            </div>

            {/* <img
              className="relative mx-auto rounded-lg shadow-lg"
              src={mainWorkflow}
              alt=""
            /> */}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection2;
