import React from "react";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { appScreenshot } from "../assets";

function ContentSection(props) {
  const posts = [
    {
      image: appScreenshot,
      title: "On-Device Transcription",
      description:
        "All transcription processing happens locally on your Mac, ensuring that your audio never leaves your device. Your data stays private and secure.",
      url: "/post/10-best-hiking-trails",
      tags: ["privacy", "transcription"],
    },
    {
      image: appScreenshot,
      title: "Unlimited Usage",
      description:
        "Transcribe as many hours of audio as you need without worrying about limits or extra costs. WhisperScript gives you the freedom to focus on your work, not your wallet.",
      url: "/post/20-best-places-star-gazing",
      tags: ["unlimited", "transcription"],
    },
    {
      image: appScreenshot,
      title: "Batch Processing",
      description:
        "Import and transcribe multiple audio or video files at once. Each file opens in a dedicated tab, allowing you to manage several projects simultaneously with ease.",
      url: "/post/how-to-explore-beautiful-lake",
      tags: ["workflow"],
    },
    {
      image: appScreenshot,
      title: "Instant Playback Navigation",
      description:
        "Click on any part of the transcript to immediately play back the corresponding audio. This feature makes reviewing and editing your transcriptions faster and more intuitive.",
      url: "/post/how-to-explore-beautiful-lake",
      tags: ["workflow", "editing"],
    },
    // {
    //   title: "Transcription",
    //   features: [
    //     {
    //       image:
    //         "https://images.unsplash.com/photo-1465056836041-7f43ac27dcb5?fit=crop&w=800&h=600",
    //       title: "On-Device Transcription",
    //       description:
    //         "All transcription processing happens locally on your Mac, ensuring that your audio never leaves your device. Your data stays private and secure.",
    //       url: "/post/10-best-hiking-trails",
    //       tags: ["privacy", "transcription"],
    //       // authorName: "Joe Smith",
    //       // authorUrl: "/author/joe-smith",
    //       // date: "March 3, 2021",
    //       // readTime: "12 min",
    //     },
    //     {
    //       image:
    //         "https://images.unsplash.com/photo-1519681393784-d120267933ba?fit=crop&w=800&h=600",
    //       title: "Unlimited Usage",
    //       description:
    //         "Transcribe as many hours of audio as you need without worrying about limits or extra costs. WhisperScript gives you the freedom to focus on your work, not your wallet.",
    //       url: "/post/20-best-places-star-gazing",
    //       tags: ["unlimited", "transcription"],
    //       // authorName: "Lia Baker",
    //       // authorUrl: "/author/lia-baker",
    //       // date: "February 23, 2021",
    //       // readTime: "20 min",
    //     },
    //     {
    //       image:
    //         "https://images.unsplash.com/photo-1501785888041-af3ef285b470?fit=crop&w=800&h=600",
    //       title: "Batch Processing",
    //       description:
    //         "Import and transcribe multiple audio or video files at once. Each file opens in a dedicated tab, allowing you to manage several projects simultaneously with ease.",
    //       url: "/post/how-to-explore-beautiful-lake",
    //       tags: ["travel", "adventure"],
    //       // authorName: "Mark Jones",
    //       // authorUrl: "/author/mark-jones",
    //       // date: "February 15, 2021",
    //       // readTime: "5 min",
    //     },
    //     {
    //       image:
    //         "https://images.unsplash.com/photo-1501785888041-af3ef285b470?fit=crop&w=800&h=600",
    //       title: "Instant Playback Navigation",
    //       description:
    //         "Click on any part of the transcript to immediately play back the corresponding audio. This feature makes reviewing and editing your transcriptions faster and more intuitive.",
    //       url: "/post/how-to-explore-beautiful-lake",
    //       tags: ["travel", "adventure"],
    //     },
    //   ],
    // },
    // {
    //   title: "Editing",
    //   features: [
    //     {
    //       image:
    //         "https://images.unsplash.com/photo-1501785888041-af3ef285b470?fit=crop&w=800&h=600",
    //       title: "Instant Playback Navigation",
    //       description:
    //         "Click on any part of the transcript to immediately play back the corresponding audio. This feature makes reviewing and editing your transcriptions faster and more intuitive.",
    //       url: "/post/how-to-explore-beautiful-lake",
    //       tags: ["travel", "adventure"],
    //     },
    //   ],
    // },
    // {
    //   title: "Workflow",
    //   features: [
    //     {
    //       image:
    //         "https://images.unsplash.com/photo-1501785888041-af3ef285b470?fit=crop&w=800&h=600",
    //       title: "Instant Playback Navigation",
    //       description:
    //         "Click on any part of the transcript to immediately play back the corresponding audio. This feature makes reviewing and editing your transcriptions faster and more intuitive.",
    //       url: "/post/how-to-explore-beautiful-lake",
    //       tags: ["travel", "adventure"],
    //     },
    //   ],
    // },
    // {
    //   title: "Privacy",
    //   features: [
    //     {
    //       image:
    //         "https://images.unsplash.com/photo-1501785888041-af3ef285b470?fit=crop&w=800&h=600",
    //       title: "Instant Playback Navigation",
    //       description:
    //         "Click on any part of the transcript to immediately play back the corresponding audio. This feature makes reviewing and editing your transcriptions faster and more intuitive.",
    //       url: "/post/how-to-explore-beautiful-lake",
    //       tags: ["travel", "adventure"],
    //     },
    //   ],
    // },
    // {
    //   title: "Built",
    //   features: [
    //     {
    //       image:
    //         "https://images.unsplash.com/photo-1501785888041-af3ef285b470?fit=crop&w=800&h=600",
    //       title: "Instant Playback Navigation",
    //       description:
    //         "Click on any part of the transcript to immediately play back the corresponding audio. This feature makes reviewing and editing your transcriptions faster and more intuitive.",
    //       url: "/post/how-to-explore-beautiful-lake",
    //       tags: ["travel", "adventure"],
    //     },
    //   ],
    // },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        {/* {featureCategory.map((category, index) => (
          <div
            className="flex flex-col items-center overflow-hidden bg-white rounded-lg shadow-sm"
            key={index}
          ></div>
        ))} */}
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
          {/* TODO: Map over feature categories, with a section header title for each featureCategory, then loop over the features (posts) in each category */}
          {/* {featureCategory.map((category, index) => (
            <div
              className="flex flex-col items-center overflow-hidden bg-white rounded-lg shadow-sm"
              key={index}
            >
              <Link
                to={`/explore/${category.slug}`}
                className="relative block group"
              >
                <div
                  className="absolute inset-0 w-full h-full bg-gray-200 opacity-75"
                  aria-hidden="true"
                ></div>
                <div className="absolute inset-0 flex items-center justify-center transition duration-150 ease-out bg-blue-700 bg-opacity-75 opacity-0 group-hover:opacity-100">
                  <ArrowUpRightIcon className="inline-block w-10 h-10 text-white" />
                </div>
                <img src={category.image} alt="" />
              </Link>
              <div className="p-5 lg:p-6">
                <h4 className="mb-2 text-lg font-bold sm:text-xl">
                  <Link
                    to={`/explore/${category.slug}`}
                    className="leading-7 text-gray-800 hover:text-gray-600"
                  >
                    {category.title}
                  </Link>
                </h4>
                <p className="mb-3 text-sm text-gray-600">{category.description}</p>
              </div>
            </div>
          ))} */}
          {posts.map((post, index) => (
            <div
              className="flex flex-col items-center overflow-hidden bg-white rounded-lg shadow-sm"
              key={index}
            >
              <Link to={post.url} className="relative block group">
                <div className="absolute inset-0 flex items-center justify-center transition duration-150 ease-out bg-blue-700 bg-opacity-75 opacity-0 group-hover:opacity-100">
                  <ArrowUpRightIcon className="inline-block w-10 h-10 text-white" />
                </div>
                <img src={post.image} alt="" />
              </Link>
              <div className="p-5 lg:p-6">
                <h4 className="mb-2 text-lg font-bold sm:text-xl">
                  <Link
                    to={post.url}
                    className="leading-7 text-gray-800 hover:text-gray-600"
                  >
                    {post.title}
                  </Link>
                </h4>
                <p className="mb-3 text-sm text-gray-600">
                  {/* <Link
                    to={post.authorUrl}
                    className="font-medium text-blue-600 hover:text-blue-400"
                  > */}
                  {/* {post.authorName} */}
                  {/* </Link>{" "} */}
                  {/* on <span className="font-medium">{post.date}</span> ·{" "} */}
                  {/* {post.readTime} read */}
                </p>
                <p className="prose">{post.description}</p>

                {post.tags && post.tags.length && (
                  <div className="mt-3 space-x-2">
                    {post.tags.map((tag, index) => (
                      <Link
                        to={`/tag/${tag}`}
                        className="font-semibold inline-flex px-2 leading-4 rounded-full text-blue-700 bg-blue-200 hover:opacity-80 text-xs py-0.5"
                        key={index}
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default ContentSection;
