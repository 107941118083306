import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
// import { Link } from "./../util/router";
import { dragDropWorkflow } from "../assets";
import { layout } from "../styles/style";

function HeroSection3(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      anchor={props.anchor}
    >
      <div className="container flex flex-col space-y-3 text-center lg:flex-row-reverse lg:space-y-0 lg:text-left">
        <div className="lg:w-1/2 lg:self-center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
          <div className="flex flex-col items-center justify-center pt-10 pb-16 space-y-2 sm:flex-row sm:items-center lg:justify-start sm:space-y-0 sm:space-x-2">
            <a href="https://getwavery.gumroad.com/l/whisperscript">
              <Button
                // component={Link}
                // to="/pricing"
                size="xl"
                endIcon={
                  <ArrowRightIcon className="inline-block w-5 h-5 opacity-70" />
                }
              >
                Start Transcribing Now
              </Button>
            </a>
            <a href={`${window.location.origin}/#features`}>
              <Button
                // component={Link}
                // to="/#features"
                size="xl"
                variant="light"
                className="w-fit"
              >
                Learn more
              </Button>
            </a>
          </div>
        </div>
        <div className="pb-12 lg:w-1/2 lg:mr-16 lg:flex lg:justify-center lg:items-center md:pb-0">
          <div className="relative">
            {/* <div className="absolute top-0 left-0 w-32 h-48 text-blue-100 transform -translate-x-16 -translate-y-12 pattern-dots md:h-96 -rotate-3" /> */}
            {/* <div className="absolute bottom-0 right-0 w-32 h-48 text-blue-100 transform translate-x-16 translate-y-12 pattern-dots md:h-96 rotate-3" /> */}
            {/* <div className="absolute top-0 right-0 w-32 h-32 -mt-12 -mr-12 bg-yellow-200 bg-opacity-50 rounded-full" /> */}
            {/* <div className="absolute bottom-0 left-0 w-32 h-32 -mb-10 -ml-10 transform bg-blue-200 bg-opacity-50 rounded-xl rotate-3" /> */}
            {/* <img
              className="relative object-cover w-full mx-auto rounded-lg shadow-lg h-96 lg:w-96 lg:h-auto"
              src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&w=800"
              alt=""
            /> */}

            {/* VIDEO */}
            <div
              style={{ aspectRatio: "auto 82/59" }}
              className={`${layout.sectionImgReverse} max-h-[400px] w-full md:mt-0 mt-10`}
            >
              <div className="rcorners aspect-[82/59] max-h-[400px]">
                <video
                  src={dragDropWorkflow}
                  alt="whisperscript-transcribing"
                  type="video/mp4"
                  className="w-full h-auto"
                  autoPlay
                  muted
                  loop
                  playsInline
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection3;
