import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function FaqSection(props) {
  const faqTopics = [
    {
      topic: "General",
      items: [
        {
          question: "What features are included?",
          answer:
            "WhisperScript offers on-device transcription powered by OpenAI’s Whisper, support for 99 languages, easy editing tools, batch processing, and export options in TXT, CSV, SRT, and VTT formats. All processing happens on your local device, ensuring your data stays private.",
        },
        {
          question: "Can I use WhisperScript on any Mac?",
          answer:
            "Yes! WhisperScript is compatible with both Apple Silicon (M1/M2/M3) and Intel Macs. It’s been tested on macOS 10.15 (Catalina) and later for optimal performance.",
        },
        {
          question: "How does WhisperScript protect my privacy?",
          answer:
            "All transcription processing is done locally on your device, meaning your audio files never leave your Mac. We prioritize your data privacy by ensuring no external servers are involved in the transcription process.",
        },
        {
          question: "Can I try WhisperScript before buying?",
          answer:
            "Yes! You can try WhisperScript Lite (Beta) for free. Just enter '0' in the price box to download and experience the core features before you commit to the full version.",
        },
        {
          question: "Do you offer customer support?",
          answer:
            "Yes, we do! You can reach out to us via our contact page or join our Discord community for support, tips, and to connect with other WhisperScript users.",
        },
        {
          question: "Can I get a refund if I’m not satisfied?",
          answer:
            "We want you to be happy with your purchase! If you’re not satisfied, please contact us within 30 days of purchase, and we’ll do our best to help you out or issue a refund.",
        },
      ],
    },
    {
      topic: "Pricing",
      items: [
        {
          question: "Is there a subscription fee?",
          answer:
            "WhisperScript offers two pricing options: a one-time payment that gives you access to a specific major version of the software, and a subscription plan that provides access to all the latest features and updates, as well as previous versions, for as long as your subscription is active.",
        },
      ],
    },
    {
      topic: "Functionality",
      items: [
        {
          question: "How accurate is the transcription?",
          answer:
            "WhisperScript uses OpenAI’s Whisper, known for its exceptional transcription accuracy across 99 languages. While results can vary depending on the quality of the audio, WhisperScript consistently delivers high accuracy, even with complex recordings.",
        },
        {
          question: "Can I edit the transcript directly within WhisperScript?",
          answer:
            "Absolutely! WhisperScript includes built-in editing tools that let you correct and refine your transcripts directly within the app. Simply click on the text to make changes, and they’ll sync with the audio playback for easy reviewing.",
        },
        {
          question: "Is WhisperScript suitable for long recordings?",
          answer:
            "Yes, WhisperScript is designed to handle long recordings efficiently. You can transcribe and edit lengthy audio files, and with the batch processing feature, manage multiple projects at once without hassle.",
        },
        {
          question: "How long does it take to transcribe a file?",
          answer:
            "Transcription speed depends on your Mac's performance and the length of the audio file. On Apple Silicon Macs, WhisperScript processes files quickly and efficiently. On older Intel Macs, the process might take a bit longer, but the results are worth the wait!",
        },
        {
          question: "Does WhisperScript work offline?",
          answer:
            "Yes, WhisperScript is designed to work entirely offline. Once you've downloaded the transcription model, no internet connection is needed, so you can transcribe your files anywhere, anytime.",
        },
        {
          question: "Can I import video files for transcription?",
          answer:
            "Definitely! WhisperScript supports transcription of video files in formats like MP4, MOV, and MKV. You can import these files just like audio, and WhisperScript will handle the rest.",
        },
        {
          question: "What languages does WhisperScript support?",
          answer:
            "WhisperScript supports transcription in 99 languages, including English, Spanish, Chinese, Russian, and many more. You can check the full list of supported languages in the app’s settings.",
        },
        {
          question: "What if I encounter a problem while using WhisperScript?",
          answer:
            "If you run into any issues, our support team is here to help! You can reach out through our contact page, or get quick assistance from our active Discord community. We're committed to making sure you have a smooth experience.",
        },
      ],
    },
    {
      topic: "Usage & Technical",
      items: [
        {
          question: "Can I transcribe files in bulk?",
          answer:
            "Yes, WhisperScript's batch processing feature allows you to import and transcribe multiple files at once. Each file opens in its own tab, making it easy to switch between projects and manage them efficiently.",
        },
        {
          question:
            "How do I update WhisperScript when new features are released?",
          answer:
            "When we release new features or updates, you'll receive a notification within the app. Updating is easy—just follow the prompt, and you'll have access to the latest tools and improvements right away.",
        },
        {
          question: "Do I need any special equipment to use WhisperScript?",
          answer:
            "No special equipment is needed! WhisperScript runs smoothly on any compatible Mac, whether it’s an Apple Silicon or Intel machine. As long as your Mac meets the minimum system requirements (macOS 10.15 or later), you’re good to go.",
        },
        {
          question:
            "Can I export my transcript to a specific format for subtitles or closed captions?",
          answer:
            "Yes! WhisperScript allows you to export transcripts in various formats including SRT and VTT, which are commonly used for subtitles and closed captions. This makes it easy to use your transcriptions in video production.",
        },
        {
          question: "Is there a limit to how much audio I can transcribe?",
          answer:
            "No, there's no limit! With WhisperScript, you can transcribe as many hours of audio as you need. It’s perfect for handling everything from short clips to full-length interviews and lectures.",
        },
        {
          question: "How do I get started with WhisperScript?",
          answer:
            "Getting started is simple! After purchasing WhisperScript, download and install the app. Once installed, import your audio or video files, and start transcribing with just a few clicks. Our intuitive interface will guide you through the process.",
        },
        {
          question: "Can I customize the transcription process?",
          answer:
            "While WhisperScript is designed to be user-friendly with powerful defaults, you can adjust settings like language preferences and file formats for export. This ensures that the transcription process aligns perfectly with your needs.",
        },
        {
          question: "What if my audio quality is poor?",
          answer:
            "WhisperScript is designed to handle a variety of audio qualities, but the accuracy might vary depending on the clarity of the recording. For the best results, we recommend using clear, high-quality audio files.",
        },
      ],
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container max-w-2xl space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />

        {faqTopics.map((topic, index) => (
          <div key={index}>
            <h3 className="mb-4 text-xl font-semibold">{topic.topic}</h3>
            <div className="space-y-4">
              {topic.items.map((item, i) => (
                <div
                  className="p-5 rounded gray-background gray-border"
                  key={i}
                >
                  <h4 className="mb-2 font-semibold">{item.question}</h4>
                  <p className="text-sm leading-relaxed text-gray-200">
                    {item.answer}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}

        {props.showSupportButton && (
          <div className="text-center">
            <div className="px-4 mb-4 text-lg font-semibold text-waveryBlue">
              Still have questions? We're here to help!
            </div>
            <Button
              href={props.supportUrl}
              target="_blank"
              size="lg"
              variant="simple"
              startIcon={
                <ArrowTopRightOnSquareIcon className="inline-block w-5 h-5 opacity-50" />
              }
            >
              Get Help from Us
            </Button>
          </div>
        )}
      </div>
    </Section>
  );
}

export default FaqSection;
